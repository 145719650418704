.scroll-container {
  background-color: rgb(255, 225, 77);
  padding: 20px;
  min-height: 100vh;
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.item-link {
  display: block;
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
}

.item-block {
  position: relative;
  background-color: #fff;
  border: 2px solid rgb(255, 225, 77);
  border-radius: 8px;
  overflow: hidden;
  width: 350px;
  height: 390px;
  display: flex;
  flex-direction: column;
}

.item-header {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.star-rating {
  display: flex;
  width: 100px;
  height: 20px;
}

.star {
  width: 20px;
  height: 20px;
  margin-right: 2px;
}

.star:last-child {
  margin-right: 0;
}

.comments-bubble {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  background-image: url('./../../assets/bubble_icon.png');
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(39, 39, 43);
  font-size: 16px;
  font-weight: 600;
}

.item-image {
  width: 350px;
  height: 350px;
  overflow: hidden;
}

.loading,
.no-more {
  color: rgb(255, 225, 77);
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .items-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  .item-block {
    width: 250px;
    height: 290px;
  }
  .item-image {
    width: 250px;
    height: 250px;
  }
  .star-rating {
    width: 80px;
    height: 16px;
  }
  .star {
    width: 16px;
    height: 16px;
  }
  .comments-bubble {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .items-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .item-block {
    width: 200px;
    height: 240px;
  }
  .item-image {
    width: 200px;
    height: 200px;
  }
  .star-rating {
    width: 60px;
    height: 12px;
  }
  .star {
    width: 12px;
    height: 12px;
  }
  .comments-bubble {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
}